<template>
  <div class="apply-home">
    <van-nav-bar
      title="报名"
      placeholder
      fixed
      z-index="999"
      style="width: 100%"
    >
      <template #right>
        <div class="nav-right" v-if="isLogin" @click="logoutClick">
          <img class="nav-img" src="../../assets/other/logout.png" alt="" />
          <span class="nav-text">退出</span>
        </div>
      </template>
    </van-nav-bar>

    <div class="pay-tip-box" v-if="order">
      <div class="left-box">
        <img
          class="left-img"
          src="../../assets/other/apply-order-icon.png"
          alt=""
        />
        <div class="center-box" type="flex" justify="space-between">
          <div class="big-text">您有一笔{{ order.statusStr }}订单</div>
          <div class="small-text">
            {{ order.classTypeStr }}-{{ order.className }}
          </div>
        </div>
      </div>

      <div class="right-box">
        <span class="look-btn" @click="toLook">去查看</span>
      </div>
    </div>

    <div
      class="curriculum-box"
      style="margin-bottom: 16px"
      v-if="kaList && kaList.length > 0"
    >
      <div class="tuan-title-box">
        <span class="iconfont icon-tuan-tags"></span>
        <span class="tuan-title">团报活动</span>
      </div>
      <van-list>
        <div
          class="curriculum-item"
          v-for="(item, i) in this.kaList"
          v-bind:key="i"
          @click="toDetail(item)"
        >
          <img class="img" :src="item.images[0]" />
          <div class="right-box">
            <div class="title">{{ item.typeDesc }}-{{ item.name }}</div>
            <div class="tags-box">
              <div
                class="tags"
                v-for="(item1, i1) in item.featureList"
                v-bind:key="i1"
              >
                <span
                  class="tags-dot"
                  v-if=" i1 < item.featureList.length"
                ></span>
                {{ item1 }}
              </div>
            </div>
            <div class="btn-box">
              <span class="price-box">
                <span class="old-price" v-if="item.originalPrice != item.price"
                  >¥{{ item.originalPrice }}</span
                >
                <span class="price">¥{{ item.price }}</span>
              </span>
              <span class="apply-btn">去报名</span>
            </div>
          </div>
        </div>
      </van-list>
    </div>

    <div class="curriculum-box" v-if="items">
      <div class="tab-box">
        <div
          v-for="(item, i) in this.items"
          v-bind:key="i"
          @click="changeTab(i)"
        >
          <div
            :class="item.typeDesc == $common.tagsId ? 'item active' : 'item'"
          >
            {{ item.typeDesc }}
          </div>
        </div>
      </div>

      <van-list>
        <div
          class="curriculum-item"
          v-for="(item, i) in this.classList"
          v-bind:key="i"
          @click="toDetail(item)"
        >
          <img class="img" :src="item.images[0]" />
          <div class="right-box">
            <div class="title">{{ item.typeDesc }}-{{ item.name }}</div>
            <div class="tags-box">
              <div
                class="tags"
                v-for="(item1, i1) in item.featureList"
                v-bind:key="i1"
              >
                <span
                  class="tags-dot"
                  v-if=" i1 < item.featureList.length"
                ></span>
                {{ item1 }}
              </div>
            </div>
            <div class="btn-box">
              <span class="price-box">
                <span class="old-price" v-if="item.originalPrice != item.price"
                  >¥{{ item.originalPrice }}</span
                >
                <span class="price">¥{{ item.price }}</span>
              </span>
              <span class="apply-btn">去报名</span>
            </div>
          </div>
        </div>
      </van-list>
    </div>

    <a class="kf-img-box" :href="'tel:' + contactPhone">
      <img class="kf-img" src="../../assets/home/kf.png" />
      <!-- <span class="iconfont icon-kf-img"></span>
      <span class="kf-title">咨询</span> -->
    </a>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  CellGroup,
  Tag,
  NavBar,
  List,
  PullRefresh,
  Dialog,
} from "vant";
import { ClassList_Api } from "@/api/class";
import { Logout_Api } from "@/api/login";
// import { OrderList_Api } from "@/api/order";
import {
  getLocalStorage,
  removeLocalStorage,
} from "@/core/utils/local-storage";
import { parse } from "qs";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tag.name]: Tag,
    [NavBar.name]: NavBar,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Dialog.name]: Dialog,
  },

  data() {
    return {
      items: [],
      classList: [],
      selectIndex: 0,
      order: null,
      contactPhone: "",
      schoolName: "",
      isLoading: false,
      isLogin: false,
      kaList: null,
    };
  },

  mounted() {
    this.upLoadData();
    //     this.$reqGet(OrderList_Api, { schoolId: schoolId.schoolId }).then((res) => {
    //   let items = res.map.items;
    //   this.items = items;
    //   this.classList = items[0].items;
    // });
  },

  methods: {
    onRefresh() {
      this.upLoadData();
    },
    upLoadData() {
      var { memberInfo } = getLocalStorage("memberInfo");
      if (memberInfo) {
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }

      this.isLoading = true;
      let query = parse(getLocalStorage("query"));
      this.$reqGet(ClassList_Api, { ...query }).then((res) => {
        this.isLoading = false;

        let items = res.map.items;
        this.items = items;
        if (items.length > 0) {
          if (!this.$common.tagsId) {
            this.$common.tagsId = items[0].typeDesc;
            this.classList = items[0].items;
          }else{
            items.forEach(element => {
              if(element.typeDesc ==  this.$common.tagsId){
                this.classList = element.items;
              }
            });
          }
        }
        this.kaList = res.map.kaList;
        this.schoolName = res.map.schoolName;
        this.order = res.map.order;
        this.contactPhone = res.map.kefuPhone;
      });
    },
    toLook() {
      this.$router.push({ name: "orderList" });
    },
    toDetail(res) {
      this.$router.push({ name: "classDetail", params: { itemId: res.id } });
    },
    changeTab(index) {
      // this.selectIndex = index;
      this.$common.tagsId = this.items[index].typeDesc;
      this.classList = this.items[index].items;
    },
    logoutClick() {
      let that = this;

      Dialog.confirm({
        title: "提示",
        message: "确认退出登录吗？",
      }).then(() => {
        that.$reqPost(Logout_Api, {}).then(() => {
          removeLocalStorage(["memberInfo"]);
          that.upLoadData();
          that.$router.replace({ name: "login" });
        });
      });
    },
  },
};
</script>

<style lang="less">
.apply-home {
  background-color: #f1f1f1;
  margin-bottom: 80px;
  .nav-right {
    display: flex;
    align-items: center;
    .nav-img {
      width: 19px;
      height: 20px;
    }
    .nav-text {
      font-size: 12px;
      margin-left: 4px;
    }
  }

  .kf-img-box {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    position: fixed;
    right: 0;
    bottom: 90px;
    .kf-img {
      width: 70px;
      height: 70px;
    }

    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // background-color: var(--apply-header-color);

    // .iconfont {
    //   margin-top: 10px;
    //   font-size: 20px;
    // }
    // .kf-title {
    //   color: var(--theme-color);
    //   font-size: 14px;
    //   margin-top: 3px;
    // }
  }

  .curriculum-box {
    width: 100%;
    padding: 16px 0;
    background-color: white;

    .tuan-title-box {
      display: flex;
      align-items: center;
      margin: 0px 0 15px 16px;
      .tuan-title {
        color: #333333;
        font-size: 16px;
        font-weight: 500;
        margin-left: 4px;
        margin-bottom: 3px;
      }
    }
    .tab-box {
      width: 343px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      // white-space: nowrap;
      // overflow-y: auto;
      margin: 0 auto 16px auto;
    }
    .tab-box::-webkit-scrollbar {
      background-color: transparent;
    }

    .item {
      padding: 0 6px;
      height: 30px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 15px;
      display: flex;
      align-items: center;
      margin-right: 12px;
      background-color: #f1f1f1;
      color: #333333;
      margin-bottom: 8px;
    }
    .item.active {
      background-color: var(--theme-color);
      color: white;
    }
    .curriculum-item {
      margin: 0 auto 12px auto;
      width: 343px;
      min-height: 128px;
      background: linear-gradient(
        180deg,
        #f7f7f7 0%,
        rgba(255, 255, 255, 0) 100%
      );
      box-shadow: 0px 2px 12px 0px rgba(83, 16, 0, 0.05);
      border-radius: 8px;
      border: 1px solid #999999;
      display: flex;
      // align-items: center;
      .tags {
        // padding: 0 5px;
        height: 16px;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--theme-color);
        font-size: 12px;
        // margin-right: 4px;
        margin-bottom: 8px;
      }
      .tags-dot {
        background-color: var(--theme-color);
        width: 4px;
        height: 4px;
        margin: 0 5px;
        border-radius: 2px;
        min-width: 4px;
        min-height: 4px;
        max-width: 4px;
        max-height: 4px;
      }
      .img {
        width: 112px;
        height: 112px;
        border-radius: 8px;
        margin-left: 8px;
        object-fit: cover;
        margin-top: 10px;
      }
      .right-box {
        margin: 8px 12px 0 12px;
        display: flex;
        flex-direction: column;
        min-height: 110px;
        width: 100%;
      }
      .title {
        color: #333333;
        font-size: 16px;
        font-weight: 500;
      }
      .tags-box {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        flex: 10;
        margin-top: 8px;
      }
      .price-box {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;
      }
      .old-price {
        text-decoration: line-through;
        color: #999999;
        font-size: 14px;
        margin-bottom: 5px;
      }
      .btn-box {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 8px;
      }
      .price {
        font-size: 18px;
        color: #ff2525;
        font-weight: 500;
      }
      .apply-btn {
        width: 122px;
        height: 44px;
        background: var(--theme-color);
        border-radius: 7px;
        color: white;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .pay-tip-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 359px;
    height: 78px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.18);
    border-radius: 8px;
    margin: 16px auto;
    .left-img {
      width: 15px;
      height: 18px;
      margin-left: 0px;
    }
    .left-box {
      margin-left: 16px;
      display: flex;
      align-items: center;
    }
    .right-box {
      margin-right: 16px;
    }
    .loading {
      width: 15px;
      height: 18px;
    }
    .center-box {
      margin-left: 16px;
      height: 42px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .big-text {
      color: #333333;
      font-size: 15px;
      font-weight: 500;
    }
    .small-text {
      color: #999999;
      font-size: 14px;
    }
    .look-btn {
      color: #333333;
      width: 80px;
      height: 26px;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #333333;
      font-size: 16px;
    }
  }
}
</style>
